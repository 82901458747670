@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;800&family=Jost:wght@400;500;600;800&display=swap");
@mixin justifyCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
@mixin jost {
  font-family: "Jost", sans-serif;
}
@mixin inter {
  font-family: "Inter", sans-serif;
}
html {
  scroll-behavior: smooth;
  margin: 0px;
  height: 100%;
  width: 100%;
  @include inter();
}
button {
  background: none;
  border: none;
}
body {
  font-size: 16px;
  height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  color: #585858;
  min-height: 100%;
}
main {
  min-height: 100%;
}
a {
  text-decoration: none;
  color: #01a79d;
  transition: 0.3s all;
}
a:hover {
  color: #e67e22;
}
p {
  margin-block-start: 0;
  margin-block-end: 0;
}
ul {
  list-style: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}
h1,
h2,
h3,
h4 {
  font-family: "Jost", sans-serif;
}
p {
  font-family: "Inter", sans-serif;
}
h2 {
  display: block;

  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
input[type="text" i],
input[type="email" i] {
  max-width: 636px;
  width: 100%;
  border: none;
  color: #585858;
  height: 50px;
  padding: 0 15px;
  margin-bottom: 10px;
}
input[type="submit" i] {
  appearance: auto;
  user-select: none;
  white-space: pre;
  align-items: flex-start;
  text-align: center;
  cursor: default;
  box-sizing: border-box;
  color: -internal-light-dark(black, white);
  padding: 1px 6px;
  border-width: 2px;
  border-style: outset;

  border-image: initial;
}

input[type="submit" i] {
  -webkit-writing-mode: horizontal-tb !important;

  font-weight: 500;
  font-size: 16px !important;
  @include jost;
  text-rendering: auto;
  color: #fff !important;
  letter-spacing: normal;
  word-spacing: normal;
  line-height: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: block;
  text-align: center;
  cursor: pointer;
  background-color: #e67e22;
  margin: 0em;
  padding: 1px 2px;
  border-width: 0;
  border-style: inset;
  border-color: none;
  border-image: initial;
  width: 174px;
  height: 50px;
  font-weight: 400;
  transition: 0.3s all;
  &:hover {
    background-color: #01a79d;
  }
}
.image {
  width: 100%;
  height: auto;
}
header {
  margin: 0 auto;
  width: 100%;
  padding-top: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  gap: 70px;
  z-index: 1;
  top: 0;
}
.logo {
  background: url(../img/logo/suwul-logo-blue.png) no-repeat;
  background-size: contain;
  height: 105px;
  max-width: 210px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
}

.navigation__submenu {
  display: none;
  position: absolute;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 200;
}
.navigation__list--item:hover .navigation__submenu {
  display: block;
}
.dark .navigation__list--item .navigation__submenu .navigation__list {
  background-color: #2d2d2d;
}
.navigation__list--item .navigation__submenu .navigation__list {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  padding: 10px;
}
.navigation {
  transition: 0.5s all;
  &__list {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 36px;
  }
}
.navigation__list--link {
  font-size: 15px;
  color: #fff;
  @include jost;
  font-weight: 400;
}
.navigation__list--link:hover {
  color: #e67e22;
}
.video {
  &__item {
    width: 100%;
  }
}
.logos {
  margin: 0 auto;
  max-width: 600px;
  margin-top: 10px;
}
.about {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &__header-img {
    max-width: 1680px;
    width: 100%;
    margin: 20px auto;
  }
  &__logo-niw {
    max-width: 200px;
  }
  &__title {
    margin-top: 80px;
    @include justifyCenter;
    flex-direction: column;
    font-size: 19px;
    font-weight: normal;
    &--special {
      color: #01a79d;
      font-size: 42px;
      font-weight: bold;
    }
  }
  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    margin-bottom: 93px;
    &--description {
      text-align: center;
      line-height: 22px;
      margin-top: 22px;
      max-width: 390px;
      width: 100%;
      min-height: 70px;
    }
    &--item {
      margin-top: 64px;
      @include justifyCenter;
      flex-direction: column;
    }
    &--title {
      color: #161616;
      font-size: 22px;
      font-weight: 600;
      margin-top: 21px;
    }
    &--img {
      max-width: 50px;
      width: 100%;
    }
  }
}

.parallax {
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(../img/img-top-page-parallax.jpg) no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  flex-direction: column;
  &__title {
    color: #fff;
    margin-top: 191px;
    font-size: 38px;
    font-weight: 500;
    font-family: "Jost", sans-serif;
  }
  &__button {
    color: #fff;
    width: 174px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Jost", sans-serif;
    font-weight: medium;
    margin-top: 35px;
    margin-bottom: 173px;
    background: #01a79d;
    transition: 0.3s all;
    border: 1px solid #01a79d;
    &:hover {
      background: #01a79d69;
      border: 1px solid#01A79D;
    }
  }
}
.link-img {
  max-width: 200px;
  &:hover {
    transform: scale(1.1);
  }
}

.hero {
  margin: 30px auto;
  max-width: 1630px;
  width: 100%;
  display: flex;
  gap: 140px;
  flex-direction: row;
  text-align: center;

  &__img {
    max-width: 630px;
    width: 100%;
    max-height: 410px;
  }
  &__title {
    font-size: 42px;
    font-weight: 600;
    color: #01a79d;
  }
  &__description {
    text-align: center;
    font-weight: 400;
    margin: 30px 0;
  }
  &__list {
    margin-top: 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    &--caption {
      font-size: 22px;
      color: #01a79d;
      font-weight: 600;
      display: flex;
      margin-top: 10px;
      gap: 20px;
      &::before {
        content: "";
        display: block;
        width: 31px;
        height: 26px;
        background: url(../img/list-index.png) no-repeat;
        background-size: cover;
      }
    }
    &--item {
      margin-bottom: 16px;
      gap: 14px;
      display: flex;
      flex-direction: column;
    }
    &--title {
      color: #e67e22;
    }
  }
}
.text-scaler {
  display: flex;
  position: fixed;
  bottom: 10px;
  left: 10px;
  background: #fff;
}
.text-scaler__item {
  padding: 10px;
  &--defualt {
    font-size: 16px !important;
  }
  &--mid {
    font-size: 22px !important;
  }
  &--big {
    font-size: 32px !important;
  }
  &:hover {
    color: #01a79d;
  }
}

.text-scaler-mid *:not(.text-scaler__item--mid) {
  font-size: 30px !important;
}
.text-scaler-big * {
  font-size: 40px !important;
}
.statistics {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #0fb9af;
    &--dark {
      background: #01a79d;
    }
  }

  &__img {
    width: 60px;
    height: 60px;
    margin-bottom: 15px;
    margin-top: 115px;
  }
  &__counter {
    color: #fff;
    margin-bottom: 35px;
    font-size: 42px;
    font-weight: 400;
    @include jost;
  }
  &__title {
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 115px;
  }
}
.newsletter {
  margin: 115px auto;
  max-width: 1630px;
  width: 100%;
  display: flex;
  gap: 240px;

  &__form {
    max-width: 814px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-direction: column;
  }
  &__title {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 42px;
    color: #01a79d;
    @include jost;
  }
  &__input {
    max-width: 636px;
    width: 100%;
    border: none;
    color: #585858;
    height: 50px;
    padding: 0 15px;
    &--wraper {
      margin-top: 42px;
      display: flex;
    }
  }
  &__img {
    max-width: 493px;
    width: 100%;
  }
}
.parallax-footer {
  background: url(../img/paralax-wiedza.jpg) no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

.contact {
  max-width: 1630px;

  width: 100%;
  // flex-direction: row;
  // display: flex;

  text-align: center;
  margin: 99px auto;
  // gap: 120px;
  &__form {
    &--checkbox {
      display: flex;
      flex-direction: row-reverse;
      margin-bottom: 30px;
    }
  }
  &__description {
    margin-top: 30px;
  }
  &__title {
    margin-bottom: 32px;
    font-size: 42px;
    color: #01a79d;
  }
  &__info {
    &--title {
      font-size: 28px;
      color: #e67e22;
    }
  }
  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 20px;
    &--title {
      color: #161616;
      font-size: 22px;
    }
  }
}
.contact__form input {
  max-width: unset;
}

footer {
  background: #2d2d2d;
  @include justifyCenter();
  justify-content: space-around;
  padding: 36px 0;
}
.footer__logo {
  max-width: 150px;
}
.copyright {
  color: #fff;
}
.gotoup {
  display: none; /* Hidden by default */
  position: fixed; /* Fixed/sticky position */
  bottom: 20px; /* Place the button at the bottom of the page */
  right: 30px; /* Place the button 30px from the right */
  z-index: 99; /* Make sure it does not overlap */
  border: none; /* Remove borders */
  outline: none; /* Remove outline */
  cursor: pointer; /* Add a mouse pointer on hover */
  width: 36px;
  height: 36px;
  background: url(../img/go-to-top.png) no-repeat;
}

.gotoup:hover {
  animation: shake 0.5s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}
@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
.sticky {
  position: fixed !important;
  top: 0;
  width: 100%;
  z-index: 1000;
}
.dark {
  background: #2d2d2d;
}

/***************************RWD***************/
.social {
  &__list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
  &__link {
    width: 30px;
    margin-left: 10px;
    height: 30px;
    display: flex;
    font-size: 0;
    &:hover {
      transform: scale(1.25);
    }
    &--facebook {
      background: url(../img/socials/facebook-f.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }
    &--linkedin {
      background: url(../img/socials/linkedin.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}
@media (max-width: 1650px) {
  // .contact, .hero {
  //     max-width: 1200px;
  //     gap: 70px;
  // }
  .hero {
    max-width: 1200px;
    margin-top: 0px;
  }
}
@media (max-width: 1280px) {
  .hero,
  .contact,
  .newsletter {
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    align-items: center;
  }
}
@media (min-width: 1200px) {
  #hamburger {
    display: none;
  }
}
@media (max-width: 1199px) {
  .hero {
    max-width: 870px;
    margin: 0 auto;
    &__title {
      text-align: center;
    }
    &__description {
      text-align: center;
      max-width: 870px;
      margin: 0 auto;
    }
  }
  header {
    gap: 20px;
  }
  .navigation__submenu {
    position: relative;
    max-width: 300px;
  }
  .navigation__list--item .navigation__submenu .navigation__list {
    justify-content: center;
  }
  .navigation {
    display: none;
    transition: 0.5s all;
    position: absolute;
    width: 100%;
    top: 90px;

    &__list {
      padding: 10px 0;
      flex-direction: column;
      width: 100%;
      background: #2d2d2d;
      width: 100%;
      &--link {
        text-align: center;
      }
    }
  }
  #hamburger {
    height: 50px;
    width: 60px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    padding: 0;

    .line {
      fill: none;
      stroke: #01a79d;
      stroke-width: 6;
      transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
        stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);

      &1 {
        stroke-dasharray: 60 207;
        stroke-width: 6;
      }
      &2 {
        stroke-dasharray: 60 60;
        stroke-width: 6;
      }
      &3 {
        stroke-dasharray: 60 207;
        stroke-width: 6;
      }
    }
    &.open .line {
      &1 {
        stroke-dasharray: 90 207;
        stroke-dashoffset: -134;
        stroke-width: 6;
      }
      &2 {
        stroke-dasharray: 1 60;
        stroke-dashoffset: -30;
        stroke-width: 6;
      }
      &3 {
        stroke-dasharray: 90 207;
        stroke-dashoffset: -134;
        stroke-width: 6;
      }
    }
  }
}

@media (max-width: 991px) {
  header {
    justify-content: space-evenly;
  }
  .hero__title {
    font-size: 32px;
  }
  .hero__list--title {
    text-align: center;
  }
  .hero__list--item::before {
    background-size: contain;
  }
  .contact,
  .hero {
    max-width: 700px;
  }
  .about__grid {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    &--description {
      max-width: 320px;
    }
  }
  .statistics {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
}
@media (max-width: 768px) {
  footer {
    flex-direction: column;
    gap: 30px;
  }
  .navigation {
    display: none;
    transition: 0.5s all;
    position: absolute;
    width: 100%;
    top: 150px;
  }
  .contact,
  .hero {
    max-width: 300px;
  }
  .parallax__title {
    text-align: center;
  }
  .statistics,
  .contact__grid,
  .about__grid {
    display: flex;
    flex-direction: column;
  }
  .logos {
    max-width: 300px;
  }
}

.show {
  display: block;
}
.project {
  max-width: 1630px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 20px;
}
.project img {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}
.add-data {
  font-weight: bold;
  padding-bottom: 30px;
}
