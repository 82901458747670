@import "https://fonts.googleapis.com/css2?family=Inter:wght@400;600;800&family=Jost:wght@400;500;600;800&display=swap";
html {
  scroll-behavior: smooth;
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: Inter, sans-serif;
}

button {
  background: none;
  border: none;
}

body {
  color: #585858;
  width: 100%;
  height: 100vh;
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-size: 16px;
}

main {
  min-height: 100%;
}

a {
  color: #01a79d;
  text-decoration: none;
  transition: all .3s;
}

a:hover {
  color: #e67e22;
}

p {
  margin-block: 0;
}

ul {
  margin-block: 0;
  margin-inline: 0;
  padding-inline-start: 0;
  list-style: none;
}

h1, h2, h3, h4 {
  font-family: Jost, sans-serif;
}

p {
  font-family: Inter, sans-serif;
}

h2 {
  margin-block: 0;
  margin-inline: 0;
  font-weight: bold;
  display: block;
}

input[type="text" i], input[type="email" i] {
  color: #585858;
  border: none;
  width: 100%;
  max-width: 636px;
  height: 50px;
  margin-bottom: 10px;
  padding: 0 15px;
}

input[type="submit" i] {
  appearance: auto;
  -webkit-user-select: none;
  user-select: none;
  white-space: pre;
  text-align: center;
  cursor: default;
  box-sizing: border-box;
  color: -internal-light-dark(black, white);
  border-style: outset;
  border-width: 2px;
  border-image: initial;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0;
  text-shadow: none;
  text-align: center;
  cursor: pointer;
  border-style: inset;
  border-width: 0;
  border-color: none;
  border-image: initial;
  padding: 1px 6px;
  background-color: #e67e22;
  align-items: flex-start;
  width: 174px;
  height: 50px;
  margin: 0;
  padding: 1px 2px;
  font-family: Jost, sans-serif;
  font-weight: 400;
  line-height: normal;
  transition: all .3s;
  display: block;
  -webkit-writing-mode: horizontal-tb !important;
  color: #fff !important;
  font-size: 16px !important;
}

input[type="submit" i]:hover {
  background-color: #01a79d;
}

.image {
  width: 100%;
  height: auto;
}

header {
  z-index: 1;
  justify-content: center;
  align-items: center;
  gap: 70px;
  width: 100%;
  margin: 0 auto;
  padding-top: 23px;
  display: flex;
  position: absolute;
  top: 0;
}

.logo {
  background: url("suwul-logo-blue.7de714ed.png") center / contain no-repeat;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 210px;
  height: 105px;
  display: flex;
}

.navigation__submenu {
  z-index: 200;
  flex-direction: column;
  justify-content: flex-start;
  display: none;
  position: absolute;
}

.navigation__list--item:hover .navigation__submenu {
  display: block;
}

.dark .navigation__list--item .navigation__submenu .navigation__list {
  background-color: #2d2d2d;
}

.navigation__list--item .navigation__submenu .navigation__list {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  padding: 10px;
  display: flex;
}

.navigation {
  transition: all .5s;
}

.navigation__list {
  justify-content: center;
  align-items: center;
  gap: 36px;
  display: flex;
}

.navigation__list--link {
  color: #fff;
  font-family: Jost, sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.navigation__list--link:hover {
  color: #e67e22;
}

.video__item {
  width: 100%;
}

.logos {
  max-width: 600px;
  margin: 10px auto 0;
}

.about {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.about__header-img {
  width: 100%;
  max-width: 1680px;
  margin: 20px auto;
}

.about__logo-niw {
  max-width: 200px;
}

.about__title {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  font-size: 19px;
  font-weight: normal;
  display: flex;
}

.about__title--special {
  color: #01a79d;
  font-size: 42px;
  font-weight: bold;
}

.about__grid {
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 93px;
  display: grid;
}

.about__grid--description {
  text-align: center;
  width: 100%;
  max-width: 390px;
  min-height: 70px;
  margin-top: 22px;
  line-height: 22px;
}

.about__grid--item {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 64px;
  display: flex;
}

.about__grid--title {
  color: #161616;
  margin-top: 21px;
  font-size: 22px;
  font-weight: 600;
}

.about__grid--img {
  width: 100%;
  max-width: 50px;
}

.parallax {
  background: url("img-top-page-parallax.98512b1b.jpg") center / cover no-repeat fixed;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.parallax__title {
  color: #fff;
  margin-top: 191px;
  font-family: Jost, sans-serif;
  font-size: 38px;
  font-weight: 500;
}

.parallax__button {
  color: #fff;
  font-family: Jost, sans-serif;
  font-weight: medium;
  background: #01a79d;
  border: 1px solid #01a79d;
  justify-content: center;
  align-items: center;
  width: 174px;
  height: 50px;
  margin-top: 35px;
  margin-bottom: 173px;
  transition: all .3s;
  display: flex;
}

.parallax__button:hover {
  background: #01a79d69;
  border: 1px solid #01a79d;
}

.link-img {
  max-width: 200px;
}

.link-img:hover {
  transform: scale(1.1);
}

.hero {
  text-align: center;
  flex-direction: row;
  gap: 140px;
  width: 100%;
  max-width: 1630px;
  margin: 30px auto;
  display: flex;
}

.hero__img {
  width: 100%;
  max-width: 630px;
  max-height: 410px;
}

.hero__title {
  color: #01a79d;
  font-size: 42px;
  font-weight: 600;
}

.hero__description {
  text-align: center;
  margin: 30px 0;
  font-weight: 400;
}

.hero__list {
  justify-content: space-evenly;
  align-items: center;
  margin-top: 20px;
  display: flex;
}

.hero__list--caption {
  color: #01a79d;
  gap: 20px;
  margin-top: 10px;
  font-size: 22px;
  font-weight: 600;
  display: flex;
}

.hero__list--caption:before {
  content: "";
  background: url("list-index.5e24e757.png") 0 0 / cover no-repeat;
  width: 31px;
  height: 26px;
  display: block;
}

.hero__list--item {
  flex-direction: column;
  gap: 14px;
  margin-bottom: 16px;
  display: flex;
}

.hero__list--title {
  color: #e67e22;
}

.text-scaler {
  background: #fff;
  display: flex;
  position: fixed;
  bottom: 10px;
  left: 10px;
}

.text-scaler__item {
  padding: 10px;
}

.text-scaler__item--defualt {
  font-size: 16px !important;
}

.text-scaler__item--mid {
  font-size: 22px !important;
}

.text-scaler__item--big {
  font-size: 32px !important;
}

.text-scaler__item:hover {
  color: #01a79d;
}

.text-scaler-mid :not(.text-scaler__item--mid) {
  font-size: 30px !important;
}

.text-scaler-big * {
  font-size: 40px !important;
}

.statistics {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  display: grid;
}

.statistics__item {
  background: #0fb9af;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.statistics__item--dark {
  background: #01a79d;
}

.statistics__img {
  width: 60px;
  height: 60px;
  margin-top: 115px;
  margin-bottom: 15px;
}

.statistics__counter {
  color: #fff;
  margin-bottom: 35px;
  font-family: Jost, sans-serif;
  font-size: 42px;
  font-weight: 400;
}

.statistics__title {
  color: #fff;
  margin-bottom: 115px;
  font-size: 20px;
  font-weight: 400;
}

.newsletter {
  gap: 240px;
  width: 100%;
  max-width: 1630px;
  margin: 115px auto;
  display: flex;
}

.newsletter__form {
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  max-width: 814px;
  display: flex;
}

.newsletter__title {
  color: #01a79d;
  margin-bottom: 42px;
  font-family: Jost, sans-serif;
  font-size: 32px;
  font-weight: 600;
}

.newsletter__input {
  color: #585858;
  border: none;
  width: 100%;
  max-width: 636px;
  height: 50px;
  padding: 0 15px;
}

.newsletter__input--wraper {
  margin-top: 42px;
  display: flex;
}

.newsletter__img {
  width: 100%;
  max-width: 493px;
}

.parallax-footer {
  background: url("paralax-wiedza.6cde5899.jpg") center / cover no-repeat fixed;
}

.contact {
  text-align: center;
  width: 100%;
  max-width: 1630px;
  margin: 99px auto;
}

.contact__form--checkbox {
  flex-direction: row-reverse;
  margin-bottom: 30px;
  display: flex;
}

.contact__description {
  margin-top: 30px;
}

.contact__title {
  color: #01a79d;
  margin-bottom: 32px;
  font-size: 42px;
}

.contact__info--title {
  color: #e67e22;
  font-size: 28px;
}

.contact__grid {
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  display: grid;
}

.contact__grid--title {
  color: #161616;
  font-size: 22px;
}

.contact__form input {
  max-width: unset;
}

footer {
  background: #2d2d2d;
  justify-content: space-around;
  align-items: center;
  padding: 36px 0;
  display: flex;
}

.footer__logo {
  max-width: 150px;
}

.copyright {
  color: #fff;
}

.gotoup {
  z-index: 99;
  cursor: pointer;
  background: url("go-to-top.bbe670f3.png") no-repeat;
  border: none;
  outline: none;
  width: 36px;
  height: 36px;
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
}

.gotoup:hover {
  animation: .5s infinite shake;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px)rotate(0);
  }

  10% {
    transform: translate(-1px, -2px)rotate(-1deg);
  }

  20% {
    transform: translate(-3px)rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px)rotate(0);
  }

  40% {
    transform: translate(1px, -1px)rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px)rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px)rotate(0);
  }

  70% {
    transform: translate(3px, 1px)rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px)rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px)rotate(0);
  }

  100% {
    transform: translate(1px, -2px)rotate(-1deg);
  }
}

.sticky {
  z-index: 1000;
  width: 100%;
  top: 0;
  position: fixed !important;
}

.dark {
  background: #2d2d2d;
}

.social__list {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.social__link {
  width: 30px;
  height: 30px;
  margin-left: 10px;
  font-size: 0;
  display: flex;
}

.social__link:hover {
  transform: scale(1.25);
}

.social__link--facebook {
  background: url("facebook-f.ba5c245c.svg") 0 0 / contain no-repeat;
}

.social__link--linkedin {
  background: url("linkedin.4ce9be1b.svg") 0 0 / contain no-repeat;
}

@media (width <= 1650px) {
  .hero {
    max-width: 1200px;
    margin-top: 0;
  }
}

@media (width <= 1280px) {
  .hero, .contact, .newsletter {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
}

@media (width >= 1200px) {
  #hamburger {
    display: none;
  }
}

@media (width <= 1199px) {
  .hero {
    max-width: 870px;
    margin: 0 auto;
  }

  .hero__title {
    text-align: center;
  }

  .hero__description {
    text-align: center;
    max-width: 870px;
    margin: 0 auto;
  }

  header {
    gap: 20px;
  }

  .navigation__submenu {
    max-width: 300px;
    position: relative;
  }

  .navigation__list--item .navigation__submenu .navigation__list {
    justify-content: center;
  }

  .navigation {
    width: 100%;
    transition: all .5s;
    display: none;
    position: absolute;
    top: 90px;
  }

  .navigation__list {
    background: #2d2d2d;
    flex-direction: column;
    width: 100%;
    padding: 10px 0;
  }

  .navigation__list--link {
    text-align: center;
  }

  #hamburger {
    cursor: pointer;
    background-color: #0000;
    border: none;
    width: 60px;
    height: 50px;
    padding: 0;
    display: flex;
  }

  #hamburger .line {
    fill: none;
    stroke: #01a79d;
    stroke-width: 6px;
    transition: stroke-dasharray .6s cubic-bezier(.4, 0, .2, 1), stroke-dashoffset .6s cubic-bezier(.4, 0, .2, 1);
  }

  #hamburger .line1 {
    stroke-dasharray: 60 207;
    stroke-width: 6px;
  }

  #hamburger .line2 {
    stroke-dasharray: 60 60;
    stroke-width: 6px;
  }

  #hamburger .line3 {
    stroke-dasharray: 60 207;
    stroke-width: 6px;
  }

  #hamburger.open .line1 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134px;
    stroke-width: 6px;
  }

  #hamburger.open .line2 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30px;
    stroke-width: 6px;
  }

  #hamburger.open .line3 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134px;
    stroke-width: 6px;
  }
}

@media (width <= 991px) {
  header {
    justify-content: space-evenly;
  }

  .hero__title {
    font-size: 32px;
  }

  .hero__list--title {
    text-align: center;
  }

  .hero__list--item:before {
    background-size: contain;
  }

  .contact, .hero {
    max-width: 700px;
  }

  .about__grid {
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .about__grid--description {
    max-width: 320px;
  }

  .statistics {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}

@media (width <= 768px) {
  footer {
    flex-direction: column;
    gap: 30px;
  }

  .navigation {
    width: 100%;
    transition: all .5s;
    display: none;
    position: absolute;
    top: 150px;
  }

  .contact, .hero {
    max-width: 300px;
  }

  .parallax__title {
    text-align: center;
  }

  .statistics, .contact__grid, .about__grid {
    flex-direction: column;
    display: flex;
  }

  .logos {
    max-width: 300px;
  }
}

.show {
  display: block;
}

.project {
  text-align: center;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 1630px;
  margin: 0 auto;
  display: flex;
}

.project img {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.add-data {
  padding-bottom: 30px;
  font-weight: bold;
}

/*# sourceMappingURL=edukacja-przyjedzie-do-ciebie-mobilny-uniwersytet-ludowy.1196ca42.css.map */
